import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

const Button = ({ children, to }) => {
  return <Btn to={to}>{children}</Btn>;
};

const Btn = styled(Link)`
  position: relative;
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.whites[0]};
  background-color: ${({ theme }) => theme.colors.blacks[3]};
  padding: 0.3em 3em;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transition: transform 300ms ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    &::before,
    &::after {
      transform: scaleX(1);
    }
  }
`;

export default Button;
