import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

const Banner = ({ data: { title, featuredImage } }) => {
  const { t } = useTranslation();
  const img = getImage(featuredImage.childImageSharp);
  return (
    <Banners>
      <Image>
        <StaticImage
          src="../images/realistic-travel-element-background.jpg"
          alt="Beautiful Outdoor Tourism Background"
          className="gatsby-img"
        />
      </Image>
      <Title>
        <Trans>{title}</Trans>
      </Title>
    </Banners>
  );
};

const Banners = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  position: absolute;
  color: ${({ theme }) => theme.colors.blacks[3]};
  text-shadow: 0px 2px 2px rgba(93, 85, 250, 1);
  text-align: center;
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 30%;
  overflow: hidden;

  @media ${({ theme }) => theme.device.tabport} {
    padding-bottom: 56.25%;
  }

  .gatsby-img {
    position: absolute;
    bottom: 0;
  }
`;

export default Banner;
