import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import Layout from "../components/layout";
import Banner from "../components/banner";
import Button from "../components/button";
import Container from "../components/container";
import TimeIcon from "../images/assets/time.svg";
import IconLevelVeryEasy from "../images/assets/level-easy.svg";
import IconLevelEasy from "../images/assets/level-easy.svg";
import IconLevelModerate from "../images/assets/level-moderate.svg";
import IconLevelChallenging from "../images/assets/level-challenging.svg";

const DestinationsPage = ({ data: { markdown, productsMarkdown } }) => {
  const { t } = useTranslation();
  const { id, title, featuredImage } = markdown.frontmatter;
  const products = productsMarkdown.nodes.filter((elem) =>
    elem.frontmatter.category.includes(title)
  );
  const setLevelIcon = (level) => {
    if (level === "Very Easy") {
      return <IconLevelVeryEasy />;
    } else if (level === "Easy") {
      return <IconLevelEasy />;
    } else if (level === "Moderate") {
      return <IconLevelModerate />;
    } else if (level === "Challenging") {
      return <IconLevelChallenging />;
    }
  };
  const bokunImg = "https://imgcdn.bokun.tools/";
  return (
    <Layout>
      <Banner data={{ title, featuredImage }} />
      <Contents>
        <Container className="wrapper">
          <Cards>
            {products &&
              products.map((props) => {
                const {
                  sortId,
                  bokunId,
                  title,
                  excerpt,
                  img,
                  duration,
                  price,
                  difficulty,
                } = props.frontmatter;
                const excerptKey = title.split(" ").join("");
                const image = getImage(img);
                const difficultyLevelIcon = setLevelIcon(difficulty);
                return (
                  <Card key={sortId}>
                    <Link to={props.fields.slug}>
                      <ImageWrapper>
                        <GatsbyImage
                          image={image}
                          alt={t(title)}
                          className="gatsby-img"
                          loading="eager"
                        />
                      </ImageWrapper>
                    </Link>
                    <h3>
                      <Link to={props.fields.slug}>
                        <Trans>{title}</Trans>
                      </Link>
                    </h3>
                    <Info>
                      <span>
                        <TimeIcon />
                        {duration}
                      </span>
                      <span>
                        {difficultyLevelIcon}
                        {difficulty}
                      </span>
                    </Info>
                    <Description>
                      <Trans i18nKey={excerptKey}>{excerpt}</Trans>
                    </Description>
                    <Footer>
                      <span>
                        <Trans>From</Trans> <br />
                        <h3>{price}</h3>
                      </span>
                      <span>
                        <Button to={props.fields.slug}>
                          <Trans>Read More</Trans>
                        </Button>
                      </span>
                    </Footer>
                  </Card>
                );
              })}
          </Cards>
        </Container>
      </Contents>
    </Layout>
  );
};

const Contents = styled.div`
  font-size: 1.6rem;
  .wrapper {
    padding: 1.5rem 1.5rem 15rem 1.5rem;
  }

  @media ${({ theme }) => theme.device.tabport} {
    font-size: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 110em;
  margin: 0 auto;
  margin-top: -8em;
  background-color: ${({ theme }) => theme.colors.whites[1]};
  position: relative;
  box-shadow: 0 3px 20px 5px rgb(0 0 0 / 3%);
  padding: 5em 3em;
`;

const Cards = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-auto-rows: auto;
  grid-gap: 2em;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  .gatsby-img {
    transition: transform 300ms ease-in-out;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: ${({ theme }) => theme.colors.whites[0]};
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  > * {
    margin-bottom: 1em;
  }
  &:hover h3 {
    color: ${({ theme }) => theme.colors.primary};
  }
  &:hover .gatsby-img {
    transform: scale(1.1);
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    > * {
      margin-right: 0.5em;
    }
  }
  svg {
    width: 1.5em;
  }
`;

const Description = styled.p`
  border: 1px solid ${({ theme }) => theme.colors.whites[3]};
  border-width: 1px 0;
  padding: 1.5em 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export default DestinationsPage;

export const query = graphql`
  query DestinationsPageQuery($id: String!, $language: String!) {
    markdown: markdownRemark(id: { eq: $id }) {
      frontmatter {
        sortId
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 2.56
              transformOptions: { cropFocus: SOUTH }
            )
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["common", "destinations"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    productsMarkdown: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "destination" } } }
      sort: { fields: frontmatter___sortId }
    ) {
      nodes {
        frontmatter {
          sortId
          bokunId
          title
          category
          excerpt
          img {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5, width: 600)
            }
          }
          duration
          price
          difficulty
        }
        fields {
          slug
        }
      }
    }
  }
`;
